import React from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import Footer from './Footer';
import AWS from 'aws-sdk';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';

function compileDescription(parts) {
  let desc = "";
  parts.forEach(part => {
    desc = desc + " " + part;
  });
  desc.trim();
  return desc;
}

function pluck(array, key) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === key) {
      result.push(array[i]);
    }
  }
  return result;
}

function pluckHomepage(array, key) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === key) {
      if (array[i].path.indexOf('1001') > 1) {
        result.push(array[i]);
      }
    }
  }
  return result;
}

function makeAdPage(state, vehicle) {
  let result, results = {};
  for (let i = 0; i < state.length; i++) {
    if (state[i][0].id === vehicle) {
      result = state[i];
    }
  }
  results = toState(result);
  return results;
}

function toState(arr) {
  var result = [];
  var count = 1;
  arr.map(element => {
    element.id = element.id + '-' + count;
    count++;
    result.push([element]);
  });
  return result;
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      vehicles: [],
      vehiclesHomepage: [],
      vehiclesDetails: [],
      currentAdImages: [],
      inAd: false,
    };
  }

  componentDidMount() {
    const compileVehicles = (vehicle) => {
      let currentState = this.state.vehicles;
      currentState.push(vehicle);
      let newState = currentState.sort();
      let uniqueIds = [...new Set(newState.map(veh => veh.id))];
      let vehicleDetails = uniqueIds.map((key) => pluck(newState, key));
      let vehiclesHomepage = uniqueIds.map((key) => pluckHomepage(newState, key));
      this.setState({ 'vehiclesHomepage': vehiclesHomepage });
      this.setState({ 'vehicleDetails': vehicleDetails });
    };

    if (this.state.loading) {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION
      });

      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME
      };

      s3.listObjectsV2(params, (err, data) => {
        if (err) {
          console.log(err, err.stack);
        } else {
          let cars = {};
          data.Contents.map((key) => {
            if (key.Key.indexOf('-')) {
              let pathParts = key.Key.split('-');
              let vehicle = pathParts[0];
              pathParts.shift();
              pathParts.pop();
              let description = compileDescription(pathParts);
              cars = { id: vehicle, "description": description, 'path': 'https://s3.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + process.env.REACT_APP_S3_BUCKET_NAME + '/' + key.Key };
              
            }
            compileVehicles(cars);
            return null;
          });
        }
      });
    }
  }

  render() {
    const goToAd = (id) => {
      let result = makeAdPage(this.state.vehicleDetails, id[0].id);
      this.setState({ 'currentAdImages': result });
      this.setState({ 'inAd': true });
    };

    const goToInventory = () => {
      this.setState({ 'inAd': false });
    };

    const ReturnToInventory = () => {
      if (this.state.inAd) {
        return (
          <div className="d-flex justify-content-center p-2">
            <div className="d-flex justify-content-center p-2">
              <h5>{this.state.currentAdImages[0].description}</h5>
            </div>
            <Button className="btn btn-primary p-2" onClick={() => goToInventory()}>Return to All Inventory</Button>
          </div>
        );
      } else return null;
    };

    const Display = () => {
      if (this.state.inAd) {
        let result = this.state.currentAdImages.map(individualCar => (
          <div key={individualCar[0].id} className="d-flex justify-content-center">
            <React.Fragment key={individualCar[0].id}>
              <Col xs={12} md={12} lg={8} className="p-2">
                <div className="card h-100 shadow-sm p-2">
                  <img src={individualCar[0].path} className="card-img-top" alt={individualCar[0].description} />
                </div>
              </Col>
            </React.Fragment>
          </div>
        ));
        return result;
      } else {
        return this.state.vehiclesHomepage.map(individualCar => (
          <React.Fragment key={individualCar[0].id}>
            <Col xs={12} md={6} lg={4} className="p-2">
              <div className="card h-100 shadow-sm">
                <img src={individualCar[0].path} className="card-img-top" alt={individualCar[0].description} />
                <div className="card-body">
                  <h5 className="card-title">{individualCar[0].description}</h5>
                  <div className="text-center my-4">
                    <Button className="btn btn-primary" onClick={() => goToAd(individualCar)}>View More Pics</Button>
                  </div>
                </div>
              </div>
            </Col>
          </React.Fragment>
        ));
      }
    };

    return (
      <Container className="wrapper-inside">
        <Container>
          <Row>
            <ReturnToInventory />
            <Display />
          </Row>
        </Container>
        <Container>
          <Row>
            <Footer />
          </Row>
        </Container>
      </Container>
    );
  }
}
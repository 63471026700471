// src/components/HomeBanner.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomeBanner = () => {
  return (
    <Container className="text-center my-4">
      <Row>
        <Col>
        <h1 className="display-4 font-weight-bold text-primary">Business 83 Auto Sales</h1>
        <p className="lead text-secondary">7523 W. Hwy Business 83 Mission, Texas 78572</p>
          <img src="../media/mainOffice.jpeg" className="img-fluid" alt="Main Office" />
        </Col>
      </Row>
    </Container>
  );
};

export default HomeBanner;
